<template>
  <div>
    <div>每周共识策略</div>
    <el-card shadow="always" style="margin-top: 10px;">
      <div class="title">时间范围选择</div>
      <div class="week-select">
        <div>周选择</div>
        <div style="margin: 0 20px;">
          <el-select v-model="weekVal" class="m-2" placeholder="选择周" size="mini" @change="changeWeek">
            <el-option v-for="item in weekOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <!-- <el-date-picker v-model="value1" type="week" @change="changeWeek" :format="startTimeStamp + '至' + endTimeStamp" placeholder="选择周">
          </el-date-picker> -->
        </div>
        <!-- <div class="confirm-btn" @click="search">确定</div> -->
      </div>
    </el-card>

    <el-card shadow="always" style="margin-top: 20px;">
      <div class="title" style="padding: 10px 0 20px 0;border-bottom: 1px solid #dcdcdc;">股评共识内容</div>

      <div class="child-title">资金分析</div>
      <el-select v-model="fundAnalysis" class="m-2" placeholder="选择资金分析" size="mini">
        <el-option v-for="item in fundOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>

      <div class="child-title">主力板块</div>
      <div class="select-item" v-for="(item, index) in mainValues" :key="index">
        <div class="num">{{index + 1}}</div>
        <el-select v-model="item.val" class="m-2" placeholder="选择主力板块" size="mini" @change="changeMainValues(item.val, index)">
          <el-option v-for="item in mainOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <!-- <div class="select-item">
        <div class="num">2</div>
        <el-select v-model="value" class="m-2" placeholder="Select" size="mini">
          <el-option v-for="item in mainOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="select-item">
        <div class="num">3</div>
        <el-select v-model="value" class="m-2" placeholder="Select" size="mini">
          <el-option v-for="item in mainOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="select-item">
        <div class="num">4</div>
        <el-select v-model="value" class="m-2" placeholder="Select" size="mini">
          <el-option v-for="item in mainOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div> -->

      <div class="child-title">市场特征</div>
      <el-select v-model="marketFeature" class="m-2" placeholder="选择市场特征" size="mini">
        <el-option v-for="item in marketOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>

      <div class="child-title">操作策略</div>
      <el-select v-model="operatingStrategy" class="m-2" placeholder="选择操作策略" size="mini">
        <el-option v-for="item in operationOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>

      <div class="child-title">周期操作</div>
      <el-select v-model="periodicOperation" class="m-2" placeholder="选择周期操作" size="mini">
        <el-option v-for="item in periodOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>

      <div class="update-btn" @click="update">确认修改</div>

    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, getCurrentInstance, onMounted } from 'vue';
import { getIndustry, getFundAnalysisEnum, getMarketFeatureEnum, getOperatingStrategyEnum, getPeriodicOperationEnum, getWeeklyConsensusStrategy, getWeeklyDateList, modifyWeeklyConsensusStrategy, addWeeklyConsensusStrategy } from "../../../api/selectionStrategy/weekSelectionStrategy";
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  setup() {
    const state = reactive({
      startTimeStamp: "",
      endTimeStamp: "",
      weekVal: '',
      weekOptions: [],
      fundOptions: [],
      mainOptions: [],
      marketOptions: [],
      operationOptions: [],
      periodOptions: [],
      mainValues: [{
        val: ''
      }, {
        val: ''
      }, {
        val: ''
      }, {
        val: ''
      }],
      fundAnalysis: '',
      marketFeature: '',
      operatingStrategy: '',
      periodicOperation: '',
      isEdit: false
    })
    onMounted(() => {
      //主力板块
      getMainSector();
      //资金分析
      getFund();
      //市场特征
      getMarket();
      //操作策略
      getOperation();
      //周期操作
      getPeriod();
      //周选择
      getWeek();
    })
    const getWeek = () => {
      let params = {
        num: 10
      }
      getWeeklyDateList({ params }).then((res) => {
        if (res.resultState == 200) {
          res.data.forEach((item) => {
            state.weekOptions.push({
              label: `${item.startDate}~${item.endDate}`,
              value: `${item.startDate}~${item.endDate}`
            })
          })
        }
      }).catch((err) => {

      });
    }
    const changeWeek = () => {
      search();
    }
    // const changeWeek = (val) => {
    //   let timeStamp = val.getTime(); //标准时间转为时间戳，毫秒级别
    //   state.startTimeStamp = fun(timeStamp); //开始时间
    //   state.endTimeStamp = fun(timeStamp + 24 * 60 * 60 * 1000 * 6); //结束时间
    // }
    // //时间戳转为yy-mm-dd
    // const fun = (timeStamp) => {
    //   const unixtimestamp = new Date(timeStamp);
    //   const year = 1900 + unixtimestamp.getYear();
    //   const month = "0" + (unixtimestamp.getMonth() + 1);
    //   const date = "0" + unixtimestamp.getDate();
    //   return (
    //     year +
    //     "-" +
    //     month.substring(month.length - 2, month.length) +
    //     "-" +
    //     date.substring(date.length - 2, date.length)
    //   );
    // }
    const search = () => {
      state.startTimeStamp = state.weekVal.split('~')[0];
      state.endTimeStamp = state.weekVal.split('~')[1];
      state.mainValues = [{
        val: ''
      }, {
        val: ''
      }, {
        val: ''
      }, {
        val: ''
      }];
      let params = {
        endDate: state.endTimeStamp,
        startDate: state.startTimeStamp
      }
      getWeeklyConsensusStrategy(params).then((res) => {
        if (res.resultState == 200) {
          const { fundAnalysis, mainSector, marketFeature, operatingStrategy, periodicOperation } = res.data;
          state.fundAnalysis = fundAnalysis;
          if (mainSector) {
            mainSector.forEach((item, index) => {
              state.mainValues[index]['val'] = item;
            })
          }
          state.marketFeature = marketFeature;
          state.operatingStrategy = operatingStrategy;
          state.periodicOperation = periodicOperation;
          if (state.fundAnalysis) {
            state.isEdit = true;
          } else {
            state.isEdit = false;
          }
        }
      }).catch((err) => {

      });
    }
    const getMainSector = () => {
      let params = {
        searchType: 1,
      };
      getIndustry({ params }).then((res) => {
        if (res.resultState == 200) {
          state.mainOptions = res.data;
        }
      }).catch((err) => {

      });
    }
    const getFund = () => {
      getFundAnalysisEnum().then((res) => {
        if (res.resultState == 200) {
          state.fundOptions = res.data;
        }
      }).catch((err) => {

      });
    }
    const getMarket = () => {
      getMarketFeatureEnum().then((res) => {
        if (res.resultState == 200) {
          state.marketOptions = res.data;
        }
      }).catch((err) => {

      });
    }
    const getOperation = () => {
      getOperatingStrategyEnum().then((res) => {
        if (res.resultState == 200) {
          state.operationOptions = res.data;
        }
      }).catch((err) => {

      });
    }
    const getPeriod = () => {
      getPeriodicOperationEnum().then((res) => {
        if (res.resultState == 200) {
          state.periodOptions = res.data;
        }
      }).catch((err) => {

      });
    }
    const changeMainValues = (val, index) => {
      state.mainValues.forEach((item, i) => {
        if (item.val == val && i !== index) {
          ElMessage({
            message: "请勿重复添加",
            type: "warning",
          });
          state.mainValues[index]['val'] = '';
        }
      })
    }
    const update = () => {
      let mainSector = [];
      state.mainValues.forEach(item => {
        if (item.val) {
          mainSector.push(item.val)
        }
      })
      if (state.endTimeStamp == '' || state.startTimeStamp == '' || state.fundAnalysis == '' || mainSector.length === 0 || state.marketFeature == ''
        || state.operatingStrategy == '' || state.periodicOperation == '') {
        ElMessage({
          message: "请填写完整",
          type: "warning",
        });
        return;
      }
      let params = {
        endDate: state.endTimeStamp,
        fundAnalysis: state.fundAnalysis,
        mainSector: mainSector,
        marketFeature: state.marketFeature,
        operatingStrategy: state.operatingStrategy,
        periodicOperation: state.periodicOperation,
        startDate: state.startTimeStamp
      }
      if (state.isEdit) {
        modifyWeeklyConsensusStrategy(params).then((res) => {
          if (res.resultState === '200') {
            ElMessage({
              message: res.msg,
              type: "success",
            });
          }
        }).catch((err) => {

        });
      } else {
        addWeeklyConsensusStrategy(params).then((res) => {
          if (res.resultState === '200') {
            ElMessage({
              message: res.msg,
              type: "success",
            });
            search();
          }
        }).catch((err) => {

        });
      }
    }
    return {
      ...toRefs(state),
      changeWeek,
      changeMainValues,
      search,
      update
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 16px;
}
.week-select {
  display: flex;
  align-items: center;
  margin: 15px 0;
  .confirm-btn {
    background: rgb(56, 162, 138);
    color: #fff;
    padding: 10px 30px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
  }
}
.child-title {
  font-weight: bold;
  font-size: 15px;
  margin: 30px 0 10px 0;
}
.select-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .num {
    margin-right: 10px;
    font-weight: bold;
  }
}
.update-btn {
  background: rgb(56, 162, 138);
  color: #fff;
  padding: 10px 30px;
  border-radius: 5px;
  font-weight: bold;
  width: 80px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}
</style>