import { pa } from 'element-plus/lib/locale';
import { post, get, put, del } from '../../request/http';

export function getIndustry(params) {
    return get('/cts/back/policy/influenceEnum', params)
}

export function getFundAnalysisEnum(params) {
    return get('/cts/back/weeklyConsensusStrategy/fundAnalysisEnum', params)
}

export function getMarketFeatureEnum(params) {
    return get('/cts/back/weeklyConsensusStrategy/marketFeatureEnum', params)
}

export function getOperatingStrategyEnum(params) {
    return get('/cts/back/weeklyConsensusStrategy/operatingStrategyEnum', params)
}

export function getPeriodicOperationEnum(params) {
    return get('/cts/back/weeklyConsensusStrategy/periodicOperationEnum', params)
}

export function getWeeklyConsensusStrategy(params) {
    return post('/cts/back/weeklyConsensusStrategy/detail', params);
}

export function getWeeklyDateList(params) {
    return get('/cts/back/weeklyConsensusStrategy/weeklyDateList', params)
}

export function modifyWeeklyConsensusStrategy(params) {
    return post('/cts/back/weeklyConsensusStrategy/modify', params)
}

export function addWeeklyConsensusStrategy(params) {
    return post('/cts/back/weeklyConsensusStrategy/add', params)
}


